<template>
    <el-scrollbar class="login-container">
        <div class="login-wrapper">
            <div class="login-main">
                <div class="login-left">
                    <img :src="require('../assets/image/login_main.png')" alt="">
                </div>
                <div class="login-right">
                    <div class="title">欢迎登录！</div>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                        <el-form-item prop="name" style="width: 320px">
                            <el-input v-model="ruleForm.name" class="input-custom" placeholder="请输入用户账号">
                                <i slot="prefix" class="iconfont">&#xea3d;</i>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password" style="width: 320px">
                            <el-input v-model="ruleForm.password" class="input-custom" placeholder="请输入密码" type="password" @keydown.native.enter="submitForm('ruleForm')">
                                <i slot="prefix" class="iconfont">&#xe82e;</i>
                            </el-input>
                        </el-form-item>

                        <el-form-item style="margin-top: 40px">
                            <el-button round type="primary" @click="submitForm('ruleForm')" style="width: 320px">登录</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    import {login} from '@/utils/apis'
    export default {
        name: "Login",
        data() {
            return {
                ruleForm: {
                    name: '',
                    password: '',
                },
                rules: {
                    name: [
                        { required: true, message: '请输入用户账号', trigger: 'blur' },
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                    ],
                }
            }
        },
        mounted() {
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('username', this.ruleForm.name)
                        formData.append('password', this.ruleForm.password)
                        login(formData).then(res=>{
                            localStorage.setItem('role', res.data.role)
                            if (res.data.role === 1) {
                                localStorage.setItem('adminToken', res.data.token);
                                localStorage.setItem('nickname', res.data.nickname);
                                localStorage.setItem('adminAvatar', res.data.avatar);
                                localStorage.setItem('adminId', res.data.id)
                                localStorage.setItem('hosturl', res.data.host_url);
                                this.$message.success({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 1000
                                })
                                this.$router.push('/educational/school')
                            } else if (res.data.role === 2) {
                                localStorage.setItem('schoolToken', res.data.token);
                                localStorage.setItem('nickname', res.data.nickname);
                                localStorage.setItem('schoolAvatar', res.data.avatar);
                                localStorage.setItem('hosturl', res.data.host_url);
                                this.$message.success({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 1000
                                })
                                this.$router.push('/school/teacherManage/Index')
                            } else if (res.data.role === 3) {
                                localStorage.setItem('trainToken', res.data.token);
                                localStorage.setItem('nickname', res.data.nickname);
                                localStorage.setItem('trainAvatar', res.data.avatar);
                                localStorage.setItem('hosturl', res.data.host_url);
                                this.$message.success({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 1000
                                })
                                this.$router.push('/school/teacherManage/Index')
                            } else if (res.data.role === 4) {
                                localStorage.setItem('teacherId', res.data.id);
                                localStorage.setItem('teacherToken', res.data.token);
                                localStorage.setItem('nickname', res.data.nickname);
                                localStorage.setItem('teacherAvatar', res.data.avatar);
                                localStorage.setItem('hosturl', res.data.host_url);
                                this.$message.success({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 1000
                                })
                                this.$router.push('/teacher/classManage/Index')
                            } else if (res.data.role === 5) {
                                this.$message.warning({
                                    message: '请到学生端登录',
                                    duration: 1000,
                                    onClose:()=>{
                                        this.$router.push('/student/login')
                                    }
                                })
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .login-container {
        width: 100vw;
        height: 100vh;
        & > ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .login-wrapper {
            background: url("../assets/image/login_bg.png") no-repeat center center;
            background-size: cover;
            min-width: 1100px;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .login-main {
        width: 1100px;
        height: 500px;
        background: #fff;
        border-radius: 20px;
        overflow: hidden;
        display: flex;
        .login-left {
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }
        .login-right {
            width: 1%;
            flex: 1;
            padding-left: 70px;
            .title {
                font-size: 24px;
                color: #2821FC;
                margin-top: 80px;
            }
            .demo-ruleForm {
                margin-top: 90px;
            }
        }
    }

    .input-custom {
        ::v-deep {
            .el-input__inner {
                background-color: #F7F8FC;
                border-color: #F7F8FC;
                padding-left: 40px;
            }
            .el-input__prefix {
                left: 10px;
            }
            .iconfont {
                color: #999;
                font-size: 22px;
            }
        }
    }
</style>